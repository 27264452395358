<template>
  <div class="font-montserrat top">
    <div class="row py-24 px-12 oonpay-bg">
      <div class="col-md-12">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          Reset Password
        </h4>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 py-5">
          <div class="form-group">
            <label for="email/phone">Email/Phone Number</label>
            <input type="text" v-model="emailphone" class="form-control" />

            <a type="button" @click="getOTP" class="text-primary ">Send OTP</a>
          </div>

          <div class="form-group">
            <label for="firstname">Verification Code</label>
            <input type="text" v-model="verify_code" class="form-control" />
          </div>

          <div class="form-group">
            <label for="lastname">New Password</label>
            <input type="password" v-model="password" class="form-control" />
          </div>

          <div class="form-group">
            <label for="email">Confirm New Password</label>
            <input
              type="password"
              v-model="confirm_password"
              class="form-control"
            />
          </div>

          <button
            @click="ResetPassword"
            @keydown.enter="ResetPassword"
            type="button"
            class="btn btn-primary btn-block"
            :disabled="!isDisbaled"
          >
          <b-spinner v-if="loading" type="grow" ></b-spinner>
            <span v-else>RESET PASSWORD</span>
            
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-mixed-spaces-and-tabs */

export default {
  data() {
    return {
      loading:false,
      emailphone: "",
      verify_code: "",
      password: "",
      confirm_password: "",
      pass_guid: ""
    };
  },

  components: {},

  computed:{
    isDisbaled(){
     return this.verify_code.length > 0 && this.password.length > 0
    }
  },

  methods: {
    getOTP() {

      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.emailphone)) {
        this.$store
          .dispatch("default/getPhoneOTP", this.emailphone)
          .then((res) => {
            if (res.status == 200) {
              this.pass_guid = res.data;
              this.$swal({
                icon: "success",
                title: "Success",
                text: "An OTP has been sent to your phone"
              });
            }
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      } else {
        this.$store
          .dispatch("default/getEmailOTP", this.emailphone)
          .then((res) => {
            if (res.status == 200) {
              this.pass_guid = res.data;
              this.$swal({
                icon: "success",
                title: "Success",
                text: "An OTP has been sent to your email"
              });
            }
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      }
    },
    ResetPassword() {
      
      const payload = {
        pass_passcode: this.verify_code,
        pass_password: this.password,
        pass_guid: this.pass_guid
      };

      this.loading = true
      this.$store
        .dispatch("default/resetPassword", payload)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false
            this.$swal({
              icon: "success",
              title: "Oops...",
              text: "Password updated successfully"
            });

            this.$router.push("/account/client/login");
          }
        })
        .catch((err) => {
          this.loading = false
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    }
  }
};
</script>

<style scoped>
.oonpay-bg {
  background: #0c4369;
}

img {
  width: 100%; /* You can set the dimensions to whatever you want */
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
