<template>
    <div>
      <the-app-bar />
      <Resetpassword/>
      <Footer />
    </div>
  </template>
  
  <script>
  import Resetpassword from "../../../components/client/Resetpassword.vue";
import Footer from "../../../components/footer.vue";
  import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
  export default {
    components: { TheAppBar, Footer, Resetpassword }
  };
  </script>
  
  